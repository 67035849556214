import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.div`
  width: 86px;
  height: 120px;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2286%22%20height%3D%22120%22%20viewBox%3D%220%200%2086%20120%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_2372%22%20data-name%3D%22%E3%83%91%E3%82%B9%202372%22%20d%3D%22M0%2C.357H86v120L42.506%2C93.04%2C0%2C120.357Z%22%20transform%3D%22translate(0%20-0.357)%22%20fill%3D%22%23fc5f6f%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: absolute;
  top: -5px;
  left: 10px;
  filter: drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.5));
  text-align: center;
  padding: 12px 0 0;
  font-weight: 600;
  color: #ffffff;
  p {
    font-size: 3.125em;
    line-height: 1em;
  }
  ${BreakPoints.medium} {
    transform-origin: top left;
    transform: scale(0.8);
  }
  ${BreakPoints.smallest} {
    transform: scale(0.5);
  }
`

type Props = {
  /** クラス名 */
  className?: string
  /** タイトル */
  label: string
}

/**
 * 共通ナビボタン風リンクタグ
 * @param props
 * @constructor
 */
const CommonPointRibbon = (props: Props): JSX.Element => {
  const { className = '' } = props

  return (
    <Wrapper className={className}>
      <span>POINT</span>
      <p>{props.label}</p>
    </Wrapper>
  )
}

export default CommonPointRibbon
