import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.section`
  width: 100%;

  .p-section-inner {
    max-width: 1080px;
    padding: 80px 0;
    margin: 0 auto;
    position: relative;

    &:after {
      display: block;
      position: absolute;
      content: '';
      width: 70px;
      height: 70px;
      background: #f8f8f8;
      transform: rotate(45deg);
      bottom: -38px;
      left: 45%;
    }

    .p-section-feature {
      width: 100%;
      margin: 0 auto 60px;
      padding: 40px;
      background: #ffffff;
      position: relative;
      border-bottom: solid 4px #3ec7b3;

      &:after {
        display: block;
        position: absolute;
        content: '';
        width: 70px;
        height: 70px;
        background: #ffffff;
        border-bottom: solid 4px #3ec7b3;
        border-right: solid 4px #3ec7b3;
        transform: rotate(45deg);
        bottom: -38px;
        left: 45%;
      }

      .head-copy {
        margin-bottom: 40px;
      }

      ul {
        width: 100%;
        background: #fff6a8;
        padding: 36px;
        border-radius: 10px;
        position: relative;
        z-index: 1;

        li {
          display: flex;
          margin: 0 0 20px 0;

          .img-wrap {
            img {
              width: 45px;
              height: auto;
            }
          }

          .txt-wrap {
            max-width: 980px;
            margin: -5px 0 0 15px;
            white-space: pre-wrap;
            font-weight: 600;
            color: #7a4a2a;
          }

          &:last-child {
            margin: 0;

            p.txt-wrap {
              margin-bottom: 0;
            }
          }
        }
      }

      .img-man-wrap {
        position: absolute;
        width: 165px;
        right: 100px;
        z-index: 2;
        bottom: -30px;
      }
    }

    .foot-copy {
      margin-top: 100px;
      position: relative;
      z-index: 2;
    }
  }

  ${BreakPoints.xLarge} {
    .p-section-inner {
      &:after {
      }

      .p-section-feature {
        &:after {
        }

        .head-copy {
        }

        ul {
          padding-right: 200px;

          li {
            .img-wrap {
              padding: 0;
            }

            .txt-wrap {
            }
          }
        }

        .img-man-wrap {
          width: 140px;
        }
      }

      .foot-copy {
      }
    }
  }

  ${BreakPoints.medium} {
    .p-section-inner {
      padding: 80px 0;

      &:after {
      }

      .p-section-feature {
        &:after {
        }

        .head-copy {
          padding-right: 130px;
          text-align: left;
        }

        ul {
          padding-right: 36px;

          li {
            .img-wrap {
            }

            .txt-wrap {
            }

            &:last-child {
              .txt-wrap {
              }
            }
          }
        }

        .img-man-wrap {
          width: 120px;
          right: 40px;
          bottom: unset;
          top: -10px;
          z-index: 1;
        }
      }

      .foot-copy {
      }
    }
  }

  ${BreakPoints.medium} {
    .p-section-inner {
      padding: 20px;

      .p-section-feature {
        .head-copy {
        }
      }

      .foot-copy {
        margin-top: 80px;
      }
    }
  }
`

/**
 * サービスサイト下層導入効果1
 * @constructor
 */
const OfficialLowBenefit01 = () => (
  <Wrapper>
    <div className="p-section-inner">
      <div className="p-section-feature fade-in-auto">
        <img
          src="/images/official/feature/imgLowFeature01.png"
          alt="キンクラの導入効果1"
          className="img-man-wrap"
        />
        <p className="head-copy txt-center txt-size-26 txt-bold-green">
          こんなことでお悩みではありませんか？
        </p>
        <ul>
          <li>
            <p className="img-wrap">
              <img
                src="/images/official/feature/imgLowFeatureCheck01.png"
                alt="よくあるお悩み"
                className="img-wrap"
              />
            </p>
            <p className="txt-wrap txt-size-26">
              自社と常駐先それぞれの勤務時間を管理しなければならない
            </p>
          </li>
          <li>
            <p className="img-wrap">
              <img
                src="/images/official/feature/imgLowFeatureCheck01.png"
                alt="よくあるお悩み"
                className="img-wrap"
              />
            </p>
            <p className="txt-wrap txt-size-26">
              シフトがバラバラすぎてシステムが導入できない
            </p>
          </li>
          <li>
            <p className="img-wrap">
              <img
                src="/images/official/feature/imgLowFeatureCheck01.png"
                alt="よくあるお悩み"
                className="img-wrap"
              />
            </p>
            <p className="txt-wrap txt-size-26">
              Excel・紙での勤怠データの管理が限界に‥‥
            </p>
          </li>
        </ul>
      </div>
      <p className="foot-copy txt-center txt-size-26 txt-bold">
        キンクラ（勤怠管理クラウド）が、これらのお悩みを解決します！
      </p>
    </div>
  </Wrapper>
)

export default OfficialLowBenefit01
