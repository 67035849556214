import styled from '@emotion/styled'
import { graphql, PageProps } from 'gatsby'
import React from 'react'
import SEO from '../components/atoms/seo'
import OfficialGalleryBtnSet from '../components/molecules/officialGalleryBtnSet'
import OfficialLayout from '../components/organisms/officialLayout'
import OfficialLowBenefit01 from '../components/organisms/officialLowBenefit01'
import OfficialLowBenefit02 from '../components/organisms/officialLowBenefit02'
import OfficialLowHeader from '../components/organisms/officialLowHeader'

const Wrapper = styled.main``

type IProps = PageProps<GatsbyTypes.IndexPageQuery>

/**
 * 導入効果ページ[サービスサイト導入効果]
 * @constructor
 */
const BenefitPage = ({ location }: IProps) => (
  <OfficialLayout location={location} className="p-page-benefit">
    <SEO title="導入効果" />
    <Wrapper>
      <OfficialLowHeader
        label="導入効果"
        description="キンクラが勤怠管理に関するお悩みを解消します"
      />
      <OfficialLowBenefit01 />
      <OfficialLowBenefit02 />
      <OfficialGalleryBtnSet />
    </Wrapper>
  </OfficialLayout>
)

export default BenefitPage

export const pageQuery = graphql`
  query BenefitPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
