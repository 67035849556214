import styled from '@emotion/styled'
import { navigate } from 'gatsby-link'
import React from 'react'
import OfficialCommonBtn from '../atoms/officialCommonBtn'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.div`
  width: 100%;
  background: #ffffff;
  text-align: center;
  position: relative;

  .p-section-inner {
    max-width: 480px;
    padding: 60px 0;
    margin: 0 auto;
    position: relative;

    .p-section-gallery {
      width: 100%;
      margin: 0 auto 60px;
      padding: 30px 0;
      background: #ffffff;
      position: relative;
      border-bottom: solid 4px #ffc60c;

      &:after {
        display: block;
        position: absolute;
        content: '';
        width: 60px;
        height: 60px;
        background: #ffffff;
        border-bottom: solid 4px #ffc60c;
        border-right: solid 4px #ffc60c;
        transform: rotate(45deg);
        bottom: -32px;
        left: 44%;
      }

      p {
        position: relative;
        z-index: 2;
      }

      img {
        display: block;
        position: absolute;
        width: 40px;
        height: auto;
        top: 0;
        left: -40px;
      }
    }
  }
`
/**
 * ギャラリー誘導ボタンセット
 * @constructor
 */
const OfficialGalleryBtnSet = () => {
  const routeParam = useRouteParam('/gallery/')
  return (
    <Wrapper>
      <div className="p-section-inner">
        <div className="p-section-gallery anim-fade-up">
          <p className="txt-center txt-size-26 txt-bold-green">
            ご好評いただいている機能を公開中です
          </p>
          <img
            src="/images/official/common/iconSupport01.png"
            alt="最大2ヶ月無料"
          />
        </div>
        <OfficialCommonBtn
          size="mega"
          onClick={() => {
            navigate(routeParam)
          }}
        >
          ギャラリー
        </OfficialCommonBtn>
      </div>
    </Wrapper>
  )
}
export default OfficialGalleryBtnSet
