import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import CommonPointRibbon from '../atoms/commonPointRibbon'

const Wrapper = styled.section`
  width: 100%;
  background-color: #ffffff;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%20%3Cdefs%3E%20%3Cstyle%3E%20.cls-1%2C%20.cls-3%20%7B%20fill%3A%20none%3B%20%7D%20.cls-1%20%7B%20stroke%3A%20%230000000a%3B%20%7D%20.cls-2%20%7B%20stroke%3A%20none%3B%20%7D%20%3C%2Fstyle%3E%20%3C%2Fdefs%3E%20%3Cg%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_23613%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%2023613%22%20class%3D%22cls-1%22%3E%20%3Crect%20class%3D%22cls-2%22%20width%3D%2212%22%20height%3D%2212%22%2F%3E%20%3Crect%20class%3D%22cls-3%22%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2211%22%20height%3D%2211%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E');
  background-repeat: repeat;
  padding: 120px 0 1px;
  border-bottom: solid 4px #00dac5;
  .p-section-inner {
    width: 100%;
    margin: 0 auto 40px;
    &.p-feature-img01 {
      background: url(/images/official/feature/bgFeature01.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right top;
    }
    &.p-feature-img02 {
      background: url(/images/official/feature/bgFeature02.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left top;
    }
    &.p-feature-img03 {
      background: url(/images/official/feature/bgFeature03.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right top;
    }
    .p-section-feature {
      width: 100%;
      margin: 0 auto;
      padding: 10px 0 80px;
      position: relative;
      max-width: 1300px;
      &:last-child {
        margin-bottom: 0;
      }
      .point-wrap {
        width: 700px;
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22335.234%22%20height%3D%22246.074%22%20viewBox%3D%220%200%20335.234%20246.074%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_2415%22%20data-name%3D%22%E3%83%91%E3%82%B9%202415%22%20d%3D%22M-14306.3%2C650.521l110.393%2C116.884%2C181.818-181.82%22%20transform%3D%22translate(14328.107%20-564.373)%22%20fill%3D%22none%22%20stroke%3D%22%23fffce4%22%20stroke-width%3D%2260%22%2F%3E%3C%2Fsvg%3E');
        background-repeat: no-repeat;
        background-position: 120% 120%;
        background-color: #ffffff;
        border-radius: 10px;
        border: solid 4px #bef1e5;
        padding: 40px 54px;
        margin: 20px 0 0;
        position: relative;
        .label-txt {
          margin: 0 auto 35px;
          text-align: center;
          white-space: pre-line;
          line-height: 1.2em;
        }
        .detail-txt {
          line-height: 1.9em;
        }
      }
      &.point-02 {
        .point-wrap,
        .point-img-wrap {
          margin-left: auto;
        }
      }
      .point-img-wrap {
        width: 700px;
        overflow: hidden;
        border-radius: 10px;
        border: solid 4px #e5e5e5;
        background-color: #ffffff;
        padding: 0;
        margin: 40px 0 0;
        img {
          display: block;
          width: 100%;
        }
      }
    }
  }
  ${BreakPoints.xLarge} {
    padding: 80px 0 20px;
    .p-section-inner {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
      &.p-feature-img01 {
        background: url(/images/official/feature/bgFeature01.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center bottom;
      }
      &.p-feature-img02 {
        background: url(/images/official/feature/bgFeature02.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center bottom;
      }
      &.p-feature-img03 {
        background: url(/images/official/feature/bgFeature03.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center bottom;
      }
      .p-section-feature {
        padding-bottom: 40px;
        .point-wrap {
          width: calc(100vw - 40px);
          margin: 20px auto 0;
          .label-txt {
          }
          .detail-txt {
          }
        }
        &.point-02 {
          .point-wrap,
          .point-img-wrap {
          }
        }
        .point-img-wrap {
          width: calc(100vw - 40px);
          margin: 40px auto 0;
        }
      }
    }
  }
  ${BreakPoints.large} {
    .p-section-inner {
      .p-section-feature {
        .point-wrap {
          .label-txt {
            padding-left: 40px;
          }
          .detail-txt {
          }
        }
        .point-img-wrap {
        }
      }
    }
  }
  ${BreakPoints.medium} {
    padding-bottom: 1px;
    .p-section-inner {
      .p-section-feature {
        .point-wrap {
          .label-txt {
          }
          .detail-txt {
            br {
              display: none;
            }
          }
        }
        .point-img-wrap {
        }
      }
    }
  }
  ${BreakPoints.smallest} {
    .p-section-inner {
      .p-section-feature {
        .point-wrap {
          padding: 20px;
          .label-txt {
            font-size: 1.175em;
            margin-bottom: 20px;
          }
          .detail-txt {
            line-height: 1.5em;
          }
        }
        .point-img-wrap {
          display: none;
        }
      }
    }
  }
`

/**
 * サービスサイト下層導入効果2
 * @constructor
 */
const OfficialLowBenefit02 = () => (
  <Wrapper>
    {/** ポイント1 */}
    <div className="p-section-inner p-feature-img01 anim-fade-up">
      <div className="p-section-feature point-01">
        <div className="point-wrap">
          <CommonPointRibbon label="1" />
          <h4 className="label-txt txt-size-30 txt-bold-green">
            自社と常駐先の勤務時間を
            <br />
            両軸管理
          </h4>
          <p className="detail-txt">
            客先常駐している社員が多く在籍していると、自社と常駐先それぞれの勤務表を管理するのは骨が折れます。
            <br />
            キンクラでは「自社」と「常駐先」2つの勤務時間をまとめて管理することが可能です。勤務地をもとにそれぞれの勤務時間をキンクラが自動集計するので、労務管理の手間と工数を大幅に削減できます。
            <br />
          </p>
        </div>
        <div className="point-img-wrap">
          <img
            src="/images/official/feature/imgFeatureScreen01.png"
            alt="キンクラ勤務表画面"
          />
        </div>
      </div>
    </div>

    {/** ポイント2 */}
    <div className="p-section-inner p-feature-img02 anim-fade-up">
      <div className="p-section-feature point-02">
        <div className="point-wrap">
          <CommonPointRibbon label="2" />
          <h4 className="label-txt txt-size-30 txt-bold-green">
            十人十色のシフトも
            <br />
            ラクラク管理
          </h4>
          <p className="detail-txt">
            変形労働時間制を採用していて夜勤、日勤、時短など…シフトが全員バラバラだと、労務管理者が従業員全員のシフトスケジュールを用意するには限界がありました。
            <br />
            キンクラなら、従業員が自分自身のシフトを自由に作成することができ、労務管理者は承認するだけで良いため、シフト管理の業務効率が飛躍的に向上します。
          </p>
        </div>
        <div className="point-img-wrap">
          <img
            src="/images/official/feature/imgFeatureScreen02.png"
            alt="キンクラシフト画面"
          />
        </div>
      </div>
    </div>

    {/** ポイント3 */}
    <div className="p-section-inner p-feature-img03 anim-fade-up">
      <div className="p-section-feature point-03 ">
        <div className="point-wrap">
          <CommonPointRibbon label="3" />
          <h4 className="label-txt txt-size-30 txt-bold-green">
            有給取得も残業も一元管理
          </h4>
          <p className="detail-txt">
            有給取得状況や残業時間をExcelにまとめて管理するのも一苦労。
            <br />
            キンクラを導入すれば、年間の有給取得義務の管理や毎月の時間外労働・休日出勤などの勤怠データはシステムで一元管理できます。
            <br />
            さらに一定の基準を超えた働き方をしている従業員が確認されると、自動アラートで本人と管理職へお知らせされるので、問題の発生を未然に防ぐことができます。
          </p>
        </div>
        <div className="point-img-wrap">
          <img
            src="/images/official/feature/imgFeatureScreen03.png"
            alt="キンクラ画面"
          />
        </div>
      </div>
    </div>
  </Wrapper>
)

export default OfficialLowBenefit02
